<template>
  <div class="page">
    <div class="page-header">
      <a-form-model
        class="ant-advanced-search-form"
        :labelCol="{span: 6}"
        :wrapperCol="{span: 18}"
        :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col
            :span="8"
          >
            <a-form-model-item label="手机号" prop="mobileNo">
              <a-input v-model="form.mobileNo" :maxLength="11" autocomplete="off" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :offset="2" of :style="{ textAlign: 'left' }">
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-row class="page-content">
      <a-col :span="24">
        <a-table
          :scroll="{ x: 2500 }"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :row-key="record => record.id"
          @change="handlePageChange"
        >
        <span slot="deviceInfo" slot-scope="text, record">
          <a @click="showInfo(record, 'deviceInfo')">查看</a>
        </span>
        <span slot="baseInfo" slot-scope="text, record">
          <a @click="showInfo(record, 'baseInfo')">查看</a>
        </span>
        </a-table>
      </a-col>
    </a-row>
    <a-modal
      v-model="detailing"
      title="查看信息"
      @ok="() => detailing = false"
      okText="关闭"
      :bodyStyle="{maxHeight: '400px', 'overflow': 'scroll'}"
      :cancelButtonProps="{style: { display: 'none' }}"
      >
       <pre>{{ detailInfo }}</pre>
    </a-modal>
  </div>
</template>

<script>
import { creditApplyList } from '@/api/credit';

const columns = [
  {
    title: '授信流水号',
    dataIndex: 'applyNo',
    key: 'applyNo',
  },
  {
    title: '渠道方编码',
    dataIndex: 'channelCode',
    key: 'channelCode',
    width: 110,
  },
  {
    title: '渠道方流水号',
    dataIndex: 'channelApplyNo',
    key: 'channelApplyNo',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '失败原因',
    dataIndex: 'failReason',
    key: 'failReason',
  },
  {
    title: '资金方编码',
    key: 'fundCode',
    dataIndex: 'fundCode',
  },
  {
    title: '资金方流水号',
    key: 'fundApplyNo',
    dataIndex: 'fundApplyNo',
  },
  {
    title: '授信申请时间',
    key: 'applyTime',
    dataIndex: 'applyTime',
  },
  {
    title: '授信成功时间',
    key: 'successTime',
    dataIndex: 'successTime',
  },
  {
    title: '手机号',
    key: 'mobileNo',
    dataIndex: 'mobileNo',
  },
  {
    title: '身份证号',
    key: 'idNo',
    dataIndex: 'idNo',
  },
  {
    title: '姓名',
    key: 'name',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: '授信金额',
    key: 'grantAmount',
    dataIndex: 'grantAmount',
  },
  {
    title: '授信利率',
    key: 'creditRate',
    dataIndex: 'creditRate',
  },
  {
    title: '利率类型',
    key: 'creditRateType',
    dataIndex: 'creditRateType',
  },
  {
    title: '设备信息',
    key: 'deviceInfo',
    scopedSlots: { customRender: 'deviceInfo' },

  },
  {
    title: '用户基本信息',
    key: 'baseInfo',
    scopedSlots: { customRender: 'baseInfo' },
  },
];

export default {
  name: 'Home',
  data() {
    return {
      data: [],
      columns,
      expand: false,
      form: {
        mobileNo: '',
      },
      detailInfo: '',
      detailing: false,
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    showInfo(record, type) {
      this.detailInfo = JSON.stringify(JSON.parse(record[type] || ''), null, 2);
      this.detailing = true;
    },
    handlePageChange(current) {
      this.pagination.current = current.current;
      this.fetchPage();
    },
    handleSearch(e) {
      e.preventDefault();
      this.fetchPage();
    },
    async fetchPage() {
      this.loading = true;
      const { result, success, errorMsg } = await creditApplyList({
        ...this.form,
        limit: 10,
        offset: this.pagination.current,
      });
      this.loading = false;
      if (!success) {
        this.$message.error(errorMsg);
        return;
      }
      this.data = result.authApplyFlowList;
      this.pagination.total = result.total;
    },
  },
  mounted() {
    this.fetchPage();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>
